<script setup lang="ts">
import src from '@/assets/icons/arrow/down.svg';
import type { IconProps } from '@/interfaces';

const props = defineProps<IconProps>();
</script>

<template>
  <common-icon class="icon-arrow-down" v-bind="props" :src="src"></common-icon>
</template>

<style scoped lang="scss"></style>
